import { signal } from "@preact/signals"
import { setCookie } from "../data/cookie.ts"
import { verifyChars } from "../data/utils.ts"

const maxLength = 25

const text = signal('')
const textOk = signal(true)
const verifyText = () => {
	const t = text.value
	if (t.length > maxLength) { textOk.value = false; return }
	if (t.length < 1) { textOk.value = true; return }
	if (!verifyChars(t)) { textOk.value = false; return }
	textOk.value = true; return
}

const send = () => {
	if (text.value.length < 1) { return }
	if (!textOk.value) return
	setCookie('name', text.value)
	location.reload()
}

export default function NameSet() {
	return <div>
		<label for="nameInput"
			class="block px-2 py-1"
			title="will be set as a cookie"
		>your name</label>
		<input type="text" id="nameInput" value={text}
			// deno-lint-ignore no-explicit-any
			onInput={(e:any) => { text.value = e.target.value; verifyText()}}
			// deno-lint-ignore no-explicit-any
			onKeyPress={(e:any) => { if (e.keyCode == 13) send() }}
			class="bg-transparent rounded-2xl border-blue-50 border-2 p-2"
			style={{
				border: !textOk.value ? '3px solid red' : '2px solid #EFF6FF',
			}}
		/>
		<button
			onClick={() => send()}
			class="p-2 m-1 bg-blue-50 text-indigo-500 rounded-2xl hover:bg-blue-200
			       transition-all duration-75"
			title="will be set as a cookie"
		>
			set
		</button>
	</div>
}
