
export const exprDays = 1

export const CookieFormat = {
	name: 'string'
}

export const getCookie = (c :string) :string|undefined => {
	const cookies = document.cookie.split(';')
	for (const cookie of cookies) {
		const [name, value] = cookie.trim().split('=')
		if (name === c) return value
	}
}

export const getCookieFrom = (c :string, src :string) :string|undefined => {
	const cookies = src.split(';')
	for (const cookie of cookies) {
		const [name, value] = cookie.trim().split('=')
		if (name === c) return value
	}
}

export const setCookie = (c :string, val :string) => {
	const exp = new Date()
	exp.setDate(exp.getDate() + exprDays)
	const cookieString =
		`${encodeURIComponent(c)}=${encodeURIComponent(val)};expires=${exp.toUTCString()};path=/;SameSite=Lax`
	console.log(cookieString)
	document.cookie = cookieString
}
